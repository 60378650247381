import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Select,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const FileListTable = ({
  largeFilesData,
  diskSpaceData,
  minSize,
  onFetchFiles,
  setMinSize,
  loading,
}) => {
  const [sortBy, setSortBy] = useState({ criterion: 'path', order: 'asc' });

  const diskSpaceMap = diskSpaceData.reduce((acc, item) => {
    acc[item.host] = item;
    return acc;
  }, {});

  const sortFiles = (files) => {
    const { criterion, order } = sortBy;
    return [...files].sort((a, b) => {
      let comparison = 0;
      if (criterion === 'path') {
        comparison = a.path.localeCompare(b.path);
      } else if (criterion === 'size') {
        comparison = parseInt(a.size) - parseInt(b.size);
      }
      return order === 'asc' ? comparison : -comparison;
    });
  };

  const toggleSortOrder = () => {
    setSortBy((prev) => ({
      ...prev,
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <Box p={3} sx={{ width: '70%', margin: '0 auto' }}>
      <Box sx={{ width: '100%', margin: '0 auto' }}>
        <Typography variant="h5" gutterBottom>
          Files over Threshold Size (MB)
        </Typography>

        <Box display="flex" alignItems="center" gap={2} mb={3}>
          <TextField
            label="Minimum Size (MB)"
            type="number"
            value={minSize}
            onChange={(e) => setMinSize(e.target.value)}
            InputProps={{ inputProps: { min: 1 } }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={onFetchFiles}
            disabled={loading}
          >
            {loading ? 'Fetching...' : 'Fetch Files'}
          </Button>
          <Select
            value={sortBy.criterion}
            onChange={(e) => setSortBy({ ...sortBy, criterion: e.target.value })}
            displayEmpty
            sx={{ width: '150px' }}
          >
            <MenuItem value="path">Sort by Path</MenuItem>
            <MenuItem value="size">Sort by Size</MenuItem>
          </Select>
          <IconButton onClick={toggleSortOrder} title="Toggle Sort Order">
            {sortBy.order === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
        </Box>

        {largeFilesData.length === 0 ? (
          <Typography align="center">
            {loading ? 'Fetching...' : 'No data available.'}
          </Typography>
        ) : (
          largeFilesData.map((server, index) => {
            const diskSpace = diskSpaceMap[server.server] || {};

            return (
              <Accordion key={index} sx={{ width: '100%' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1" noWrap>
                    {server.server} ({server.files.length} files) - 
                    {diskSpace.availableMB
                      ? ` ${diskSpace.availableMB} MB free / ${diskSpace.totalMB} MB total (${diskSpace.availablePercent}%)`
                      : ' Disk data unavailable'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{maxHeight:200, overflowY:'auto'}}>
                  {sortFiles(server.files).map((file, fileIndex) => (
                    <Box
                      key={fileIndex}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        padding: '8px 0',
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      <Typography
                        sx={{
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                          whiteSpace: 'normal',
                          flex: 3,
                          paddingRight: '16px',
                          maxWidth: '90%',
                        }}
                      >
                        {file.path}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: 'right',
                          flexShrink: 0,
                          flex: 1,
                        }}
                      >
                        {file.size}
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </Box>
    </Box>
  );
};

export default FileListTable;
