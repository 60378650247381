import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SiteLogsTable from '../../components/Dashboard/Logs/LogsTables';
import FileListTable from '../../components/FileListTable/FileListTable';
import { serverHost } from '../../redux/reducers/reducer';

export default function Logs() {
  const serverhost = serverHost();
  const [errorData, setErrorData] = useState([]);
  const [warnData, setWarnData] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const [largeFilesData, setLargeFilesData] = useState([]);
  const [tabValue, setTabValue] = useState('info');
  const [minSize, setMinSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [diskSpaceData, setDiskSpaceData] = useState([]); // New state for disk space data

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${serverhost}/site/getAllBigFiles?minSize=${minSize}`
      );
      const data = response.data;

      const aggregatedData = data.map((server) => ({
        server: server.host,
        files: server.data
          .split('\n')
          .filter(Boolean)
          .map((line) => {
            const [path, size] = line.split(': ');
            return { path, size };
          }),
      }));

      setLargeFilesData(aggregatedData);
    } catch (error) {
      console.error('Error fetching large files:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDiskSpace = async () => {
    try {
      const response = await axios.get(`${serverhost}/site/getAllServersDiskSpace`);
      setDiskSpaceData(response.data);
    } catch (error) {
      console.error('Error fetching disk space data:', error);
    }
  };

  const onReload = () => {
    axios
      .get(`${serverhost}/site/getAllLogs`)
      .then((res) => {
        const logsArray = res.data;
        const warn = [];
        const info = [];
        const error = [];

        for (const elem of logsArray) {
          const currTime = elem.log_dt;
          elem.log_dt = `${new Date(currTime).getDate()}/${
            new Date(currTime).getMonth() + 1
          }/${new Date(currTime).getFullYear()}  ${new Date(currTime).toLocaleTimeString()} `;
          switch (elem.log_level) {
            case 'Info':
              info.push(elem);
              break;
            case 'Error':
              error.push(elem);
              break;
            case 'Warn':
              warn.push(elem);
              break;
            default:
              break;
          }
        }
        setErrorData(error);
        setWarnData(warn);
        setInfoData(info);
      })
      .catch((err) => {
        setErrorData([]);
        setWarnData([]);
        setInfoData([]);
      });

    fetchFiles(); // Fetch large files on reload
    fetchDiskSpace(); // Fetch disk space data

  };

  useEffect(() => {
    onReload();
  }, []);

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          left: '50%',
          top: '10vh',
          transform: 'translate(-50%, 50%)',
        }}
      >
        <Tabs value={tabValue} onChange={handleChange} aria-label="tabs" textColor="inherit">
          <Tab value="info" label="Info" />
          <Tab value="warn" label="Warning" />
          <Tab value="error" label="Error" />
          <Tab value="files" label="Files" />
        </Tabs>
      </div>
      <div style={{ position: 'relative', top: '20vh' }}>
        {tabValue !== 'files' ? (
          <SiteLogsTable
            data={tabValue === 'info' ? infoData : tabValue === 'error' ? errorData : warnData}
            level={tabValue}
          />
        ) : (
          <FileListTable
            largeFilesData={largeFilesData}
            diskSpaceData={diskSpaceData}
            minSize={minSize}
            onFetchFiles={fetchFiles}
            setMinSize={setMinSize}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}
