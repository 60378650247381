import React, { useState } from 'react';
import { ReactComponent as Horizontal3Points } from "../../../assets/icons/general/horizontal3Points.svg";
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import './actionDots.css';
function ActionsDots({ items }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton onClick={handleClick} className="action-dots-icon">
        <Horizontal3Points />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: '0px 4px 16px rgba(24, 26, 46, 0.3)',
            borderRadius: 8,
            padding:6,
            gap:20,
          },
        }}
      >
          {items}
      </Menu>
    </>
  );
}

export default ActionsDots;
